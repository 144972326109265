<template>
  <div>
    <div class="row mb-2" v-if="contact.contact_uuid">
      <div class="col">
        <dt>Contact UUID</dt>
        <dd class="text-secondary uuid">{{ contact.contact_uuid }}</dd>
      </div>
    </div>
    <div class="alert alert-warning" role="alert" v-if="contactShared[contact.contact_uuid] && contactShared[contact.contact_uuid].length > 1">
      <p><strong>Contact is shared between multiple contact types.</strong></p>
      <p>Any changes to this contact must be manually made to each of the following contact types.</p>
      <span class="badge bg-danger" v-for="contactType in contactShared[contact.contact_uuid]" :key="contactType">
        {{ contactType }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContactShared',

    props: ['contact', 'contactShared'],
  };
</script>

<style lang="scss" scoped>
  span.badge {
    margin-right: 5px;
  }
  dd.uuid {
    font-size: 9pt;
    font-family: monospace;
  }
</style>
