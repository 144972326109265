/**
 * Generate a map of contact_uuid to array of contact_type to confirm if contact
 * is being used else where.
 * @param {Object} contacts
 * @returns {Object} Object with contact_uuid as key and array of contact_types as value
 */
function generateContactSharedMap(contacts) {
  if (!contacts || !Array.isArray(contacts) || contacts.length <= 0) {
    return {};
  }
  return contacts.reduce((acc, cur) => {
    if (!acc[cur.contact.contact_uuid]) {
      acc[cur.contact.contact_uuid] = [];
    }

    acc[cur.contact.contact_uuid].push(cur.contact_type);

    return acc;
  }, {});
}

module.exports = {
  generateContactSharedMap
};